import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import LoadScript from 'vue-plugin-load-script';
import Axios from "axios"
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import Toast from "vue-toastification";
import i18n from './i18n';
import "vue-toastification/dist/index.css";

import vuetify from '@/plugins/vuetify' // path to vuetify export
import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/dark.css'
import './assets/css/skin-modes.css'
import './assets/css/animated.css'
import './assets/css/color1.css'
import './assets/css/p-scrollbar.css'
import './assets/css/simplebar.css'


import './assets/css/vuetify.min.css' 
import './assets/css/googlefonts.css'
import './assets/css/custom.css'

import 'vue-slider-component/theme/antd.css'
import 'vue-range-component/dist/vue-range-slider.css'
import './assets/css/icons.css'
Vue.config.productionTip = false;
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
Vue.use(LoadScript);
Vue.use(PerfectScrollbar)
import Clipboard from 'v-clipboard'
 
Vue.use(Clipboard)
const options = {
    // You can set your default options here
};
let mrl = document.head.querySelector("[name~=mrl][content]");

let titleHome = document.head.querySelector("[name~=domain][content]");
store.commit('SET_MRL', mrl.content);
store.commit('SET_TITLE', titleHome.content);

Axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
        store.dispatch('logoutNow');
        document.location = '/login';
    }
    if (error.response && error.response.status === 429) {
      document.location.reload();
    }
    return Promise.reject(error);
  }
);
console.log('Init app');
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}
import { lang, translation } from './components/lang';
import Popover from 'vue-js-popover'

Vue.use(Popover);
Vue.use(Toast, options);


function generateRandomToken() {
  return crypto.randomUUID();
}

async function initializeApp() {
  try {

    // Генерация токена
    const token = generateRandomToken();

    // Устанавливаем заголовок Authorization
    Axios.defaults.headers.common['Authorization'] = `OAuth ${token}`;

    await store.dispatch('fetchCsrfToken');
    store.dispatch('startTokenRefresh');

    // Установите CSRF токен в axios для будущих запросов
    const csrfToken = store.state.csrf.token;
    Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

    window.ROOT_VUE = new Vue({
      i18n,
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
    
    window.Vue = Vue; 
  } catch (error) {
    console.error('Failed to initialize app', error);
  }
}

initializeApp();